import Color from 'color';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { isGradientColor } from '@/app/editor/themes/helpers';
import Popover from '@/ui/components/Popover';
import { cn } from '@/utils/cn';

import type { ThemeColorKey } from '@/app/editor/themes/types';
import type { Placement } from '@floating-ui/react';

const Picker = dynamic(() => import('./Picker'));

export type ColorPickerHistory = {
    previousSolidColor?: string;
    previousGradient?: string;
};

export interface Props {
    color: string;
    themeColor?: string;
    expand: 'left' | 'right' | 'center';
    presetColors?: string[];
    themeColors: string[];
    themeKey?: ThemeColorKey;
    hiddenThemingButton?: boolean;
    hasGradient?: boolean;
    onShow?: () => void;
    onHide?: () => void;
    onChange?: (color: string) => void;
    colorPickerHistory?: ColorPickerHistory;
    onColorPickerHistoryChange?: (newColorPickerHistory: ColorPickerHistory) => void;
}

const placementMap: { [expand: string]: Placement } = {
    left: 'bottom',
    center: 'bottom',
    right: 'bottom-start',
};

const ColorPicker = ({
    color,
    themeColor,
    expand = 'right',
    presetColors,
    themeColors,
    themeKey,
    onShow,
    onHide,
    onChange,
    hiddenThemingButton,
    hasGradient,
    colorPickerHistory,
    onColorPickerHistoryChange,
    ...rest
}: Props) => {
    const [visible, setVisible] = useState(false);

    const isThemed = color === 'transparent' || !color;

    const show = () => {
        setVisible(true);

        onShow?.();
    };

    const handleChange = (color: string) => {
        const isThemed = color === 'transparent' || !color;
        const colorToSet = isGradientColor(color) || isThemed ? color : Color(color).hex();

        onChange?.(colorToSet);

        if (onColorPickerHistoryChange) {
            onColorPickerHistoryChange({
                ...colorPickerHistory,
                ...(isGradientColor(color)
                    ? { previousGradient: colorToSet }
                    : !isThemed
                      ? { previousSolidColor: colorToSet }
                      : {}),
            });
        }
    };

    return (
        <Popover
            placement={placementMap[expand]}
            mainAxisOffsetValue={8}
            fallbackPlacements={['top-start', 'right']}
            flipCrossAxis={false}
            onOpenChange={(open) => !open && onHide?.()}
            render={() => {
                return (
                    <Picker
                        color={color}
                        onChange={handleChange}
                        presetColors={presetColors}
                        themeColors={themeColors}
                        hiddenThemingButton={hiddenThemingButton}
                        hasGradient={hasGradient}
                        themeKey={themeKey}
                        isThemed={isThemed}
                        colorPickerHistory={colorPickerHistory}
                    />
                );
            }}
        >
            <div className="relative">
                <button
                    className={cn(
                        'bump flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 p-2',
                        visible ? 'border-blue-500' : 'hover:border-blue-500',
                    )}
                    onClick={show}
                    type="button"
                    {...rest}
                >
                    <div
                        className="flex size-full items-center justify-center rounded-md border"
                        style={{
                            background: `${isThemed ? themeColor : color} no-repeat`,
                        }}
                    />
                </button>
            </div>
        </Popover>
    );
};

export default ColorPicker;
