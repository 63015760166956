export enum LOLI_FEATURE_FLAG_NAME {
    editorEngine = 'fun-editor-engine',
    metricsPage = 'con-metrics-page',
    emailAutomation = 'con-email-automation',
    paymentBlock = 'fun-payment-block',
    customHtmlEmbedBlockNativeEmbed = 'fun-custom-html-embed-block-native-embed',
    funnelEmbedAlpha = 'fun-funnel-embed-alpha',
    sectionTemplates = 'fun-section-templates',
    integrationsAdvanced = 'con-integrations-advanced',
}

export const OVERWRITES_STORAGE_KEY_PREFIX = 'loli_feature_flag_overwrite__';

export const LAST_INTERCOM_FEATURE_FLAG_EVENT_VALUES_LOCAL_STORAGE_KEY =
    'loli_feature_flags_last_intercom_event_values';
