import { HELP_TOOLTIP_CONTENT } from '@/app/helpTooltips/constants';

import get from 'lodash/get';
import Router from 'next/router';
import { i18n } from 'next-i18next';
import qs from 'query-string';

import { getDataFromResponse, getMetaFromResponse } from '@/core/api/helper';
import { capitalizeFirstLetter } from '@/utils/common';
import { EMPTY_STRING } from '@/utils/empty';

import {
    CAMPAIGN_DELETION_REMINDER_LS_KEY,
    CAMPAIGN_STATUS,
    CAMPAIGN_SUBHEADLINE_STATES,
    DEFAULT_COOKIE_SETTINGS,
    FILTER_QUERY_MAP,
    NAME,
} from '../constants';
import { getFilter, getOrder, getSearch } from '../models/overview';
import { CampaignFilter } from '../types';

import type { DomainResource } from '../../domains/types';
import type {
    CampaignAttributes,
    CampaignColumnName,
    CampaignResource,
    CampaignStatus,
    CampaignSubheadlineStatus,
    CampaignsWithPagination,
    CampaignTableColumnItem,
    CampaignTrackingVersion,
    CookieSettings,
} from '../types';
import type { CampaignCRMStatus } from '@/app/crm/types';
import type { HelpTooltipContentDescriptor } from '@/app/helpTooltips/types';
import type { Pagination, RelationshipObject, ResponseData } from '@/core/api/types';
import type { AppState } from '@/core/redux/types';
import type { AxiosResponse } from 'axios';

export const getCampaignVisitorCount = (campaign: CampaignResource): number => {
    return get(campaign, 'attributes.analytics.sessions', 0);
};

export const getCampaignLeadCount = (campaign: CampaignResource): number => {
    return get(campaign, 'attributes.analytics.leads', 0);
};

export const getCampaignContactCount = (campaign: CampaignResource): number => {
    const leadCount = getCampaignLeadCount(campaign);
    let count = get(campaign, 'attributes.analytics.contacts', 0);

    // if contact count hasn't been set yet, use the lead count
    if (count === 0 && leadCount > 0) {
        count = leadCount;
    }

    return count;
};

export const getCampaignCRMStatus = (campaign: CampaignResource): CampaignCRMStatus[] => {
    return get(campaign, 'attributes.analytics.status', []);
};

export const getCampaignTrackingVersion = (campaign: CampaignResource): CampaignTrackingVersion => {
    return get(campaign, 'attributes.trackingVersion');
};

export const getCampaignWorkspaceId = (campaign: CampaignResource): string => {
    return get(campaign, 'relationships.workspace.data.id');
};

export const getCampaignStatus = (
    campaign: CampaignResource,
    defaultStatus: CampaignStatus = 'draft',
): CampaignStatus => {
    return get(campaign, 'attributes.status', defaultStatus);
};

export const getCampaignName = (campaign: CampaignResource | {}): string => {
    return get(campaign, 'attributes.name', EMPTY_STRING);
};

export const getCampaignUrl = (campaign: CampaignResource): string => {
    const url = get(campaign, 'attributes.url', EMPTY_STRING);

    if (!/^https?:\/\//i.test(url)) {
        return `https://${url}`;
    }

    return url;
};

export const campaignHasStatus = (campaign: CampaignResource, status: CampaignStatus): boolean => {
    const campaignStatus = getCampaignStatus(campaign);

    return campaignStatus === status;
};

export const getCampaignConversionRate = (campaign: CampaignResource): number => {
    const visitorCount = getCampaignVisitorCount(campaign);
    const leadCount = getCampaignLeadCount(campaign);

    const conversionRate = visitorCount > 0 ? (leadCount / visitorCount) * 100 : 0;

    return Math.round(conversionRate * 1e2) / 1e2; // CK: better than using `toFixed`, stays number
};

export const getCampaignIsLive = (campaign: CampaignResource): boolean =>
    campaignHasStatus(campaign, CAMPAIGN_STATUS.live as CampaignStatus);

export const getCampaignIsBuilding = (campaign: CampaignResource): boolean =>
    campaignHasStatus(campaign, CAMPAIGN_STATUS.building as CampaignStatus);

export const getCampaignIsDraft = (campaign: CampaignResource): boolean =>
    campaignHasStatus(campaign, CAMPAIGN_STATUS.draft as CampaignStatus);

export const getCampaignIsOffline = (campaign: CampaignResource): boolean =>
    campaignHasStatus(campaign, CAMPAIGN_STATUS.offline as CampaignStatus);

export const getCampaignIsArchived = (campaign: CampaignResource): boolean =>
    get(campaign, 'attributes.isArchived');

export const getCampaignIsShared = (campaign: CampaignResource): boolean =>
    get(campaign, 'attributes.isShared');

export const getCampaignHasProgressBar = (campaign: CampaignResource): boolean =>
    get(campaign, 'attributes.showProgressBar');

export const getCampaignHasBadge = (campaign: CampaignResource): boolean =>
    get(campaign, 'attributes.showBadge');

export const getCampaignCoverImage = (campaign: CampaignResource): string =>
    get(campaign, 'attributes.coverImage');

export const getCampaignActiveVersion = (campaign: CampaignResource): RelationshipObject =>
    get(campaign, 'relationships.activeVersion.data');

export const getCampaignLatestVersion = (campaign: CampaignResource | {}): RelationshipObject =>
    get(campaign, 'relationships.latestVersion.data');

export const getCampaignFallbackUrl = (campaign: CampaignResource): string => {
    const campaignDefaultUrl = get(campaign, 'attributes.fallbackUrl', EMPTY_STRING);

    let defaultSlug = campaignDefaultUrl?.split('/')?.slice(1)?.join('/');

    return process.env.NEXT_PUBLIC_STAGING_DEFAULT_DOMAIN
        ? `${process.env.NEXT_PUBLIC_STAGING_DEFAULT_DOMAIN}/${defaultSlug}` // staging
        : campaignDefaultUrl; // production
};

export const getCampaignCookieSettings = (campaign?: CampaignResource): CookieSettings => {
    return get(campaign, 'attributes.cookieOverlay', DEFAULT_COOKIE_SETTINGS);
};

export const hasCampaignBeenPublishedBefore = (campaign?: CampaignResource): boolean => {
    return !!campaign?.attributes?.launchedAt;
};

export const getMinifiedCoverImage = (campaign: CampaignResource): string => {
    const coverImage = get(campaign, 'attributes.coverImage', '');

    // Fallback 1x1 transparent gif
    if (!coverImage) {
        return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    }

    const trimmedSrc = coverImage.split('?')[0];

    const coverImageUrl = new URL(coverImage);
    const params = new URLSearchParams(coverImageUrl.search);

    params.set('q', '' + 75);
    params.set('w', '' + 340);
    params.set('dpr', '' + 2);
    params.set('h', '' + 160);
    params.set('auto', 'compress,format');

    return trimmedSrc + `?${params.toString()}`;
};

export const getTextAndTooltipProps = ({
    status,
    isSlugEditing,
    lastUpdatedTime,
}: {
    status: CampaignSubheadlineStatus;
    isSlugEditing: boolean;
    lastUpdatedTime: string;
}): {
    text: string;
    helpTooltip?: HelpTooltipContentDescriptor;
} => {
    const t = i18n?.getFixedT(null, NAME);

    if (isSlugEditing) {
        return {
            text: t('edit link subtext'),
            helpTooltip: HELP_TOOLTIP_CONTENT.PUBLISH_EDIT_LAUNCH_URL,
        };
    }

    switch (status) {
        case CAMPAIGN_SUBHEADLINE_STATES.LIVE:
            return {
                text: t('sub status live', {
                    lastUpdatedTime: capitalizeFirstLetter(lastUpdatedTime),
                }),
            };
        case CAMPAIGN_SUBHEADLINE_STATES.BUILDING_ESTIMATE:
            return {
                text: t('sub status building'),
            };
        case CAMPAIGN_SUBHEADLINE_STATES.BUILDING_TAKING_TOO_LONG:
            return {
                text: t('sub status building taking too long'),
            };
        case CAMPAIGN_SUBHEADLINE_STATES.BUILDING_PROGRESS:
            return {
                text: t('sub status building'),
            };
        case CAMPAIGN_SUBHEADLINE_STATES.OFFLINE:
            return {
                text: t('sub status offline'),
                helpTooltip: HELP_TOOLTIP_CONTENT.PUBLISH_FUNNEL_CHECK,
            };
        default:
            return {
                text: t('sub status draft'),
                helpTooltip: HELP_TOOLTIP_CONTENT.PUBLISH_FUNNEL_CHECK,
            };
    }
};

const importantCDNStatus = ['shuttingDown', 'disabled', 'inProgress'];

const importantDnsStatus = ['verifying', 'unverified'];

const importantCertificateStatus = ['inProgress', 'failed'];

export const getMostImportantDomainStatus = (domain: DomainResource): string => {
    if (!domain) {
        return '';
    }
    const dnsStatus = get(domain, 'attributes.dns.status');
    const cdnStatus = get(domain, 'attributes.cdnStatus');
    const certificateStatus = get(domain, 'attributes.ssl.status');

    if (importantDnsStatus.indexOf(dnsStatus) > -1) {
        return cdnStatus;
    }

    if (importantCDNStatus.indexOf(cdnStatus) > -1) {
        return cdnStatus;
    }

    if (importantCertificateStatus.indexOf(certificateStatus) > -1) {
        return certificateStatus;
    }

    return get(
        domain.attributes,
        'dnsStatus',
        get(domain, 'attributes.status', get(domain, 'attributes.dns.status', '')),
    );
};

export const getPublishButtonTextByStatus = (
    status: CampaignAttributes['status'],
    wasPublishedOnce: boolean = false,
): string => {
    const statusToKey = {
        [CAMPAIGN_STATUS.draft]: 'publish now',
        [CAMPAIGN_STATUS.live]: 'republish now',
        [CAMPAIGN_STATUS.building]: wasPublishedOnce ? 'building' : 'building draft',
    };

    return statusToKey[status] || 'publish now';
};

export const getEditorUrl = (campaignId: string) => `/funnel/${campaignId}`;

export const getPublishUrl = (campaignId: string) => `/funnel/${campaignId}/publish`;

export const getEmbedUrl = (campaignId: string) => `/funnel/${campaignId}/embed`;

export const getMetricsUrl = (campaignId: string) => {
    // @todo(AD): Use it's own Metrics page url
    return `/funnel/${campaignId}/analytics/metrics`;
};

export const getAnalyticsUrl = (
    campaignId: string,
    hasMetricsTab: boolean,
    isV3Funnel: boolean,
) => {
    if (hasMetricsTab && !isV3Funnel) {
        return getMetricsUrl(campaignId);
    }

    return `/funnel/${campaignId}/analytics/dashboard`;
};

export const getMetricsSessionsUrl = (campaignId: string) => {
    return `/funnel/${campaignId}/metrics/sessions`;
};

export const getMetricsABTestUrl = (campaignId: string) => {
    // @todo(AD): Use it's own Metrics AB Test page url
    return `/funnel/${campaignId}/analytics/ab-test`;
};

export const getLinkUrl = ({
    campaignId,
    isMobile,
    isV3Funnel,
    hasMetricsPage,
}: {
    isMobile: boolean;
    hasMetricsPage: boolean;
    isV3Funnel: boolean;
    campaignId: string;
}) => {
    if (isMobile) {
        return getAnalyticsUrl(campaignId, hasMetricsPage, isV3Funnel);
    }

    return getEditorUrl(campaignId);
};

export const getAnalyticsContactsUrl = (campaignId: string) => {
    return `/funnel/${campaignId}/analytics/contacts`;
};

export const getAnalyticsSessionsUrl = (campaignId: string) => {
    return `/funnel/${campaignId}/analytics/sessions`;
};

export const getIntegrationsUrl = (campaignId: string) => {
    return `/funnel/${campaignId}/integrations`;
};

export const getCampaignQueryString = (
    {
        workspaceIds,
        crmCampaignsOnly,
        queryOptions,
    }: {
        workspaceIds?: string;
        crmCampaignsOnly?: boolean;
        queryOptions?: {
            search?: string;
            filter?: CampaignFilter;
        };
    },
    state: AppState,
) => {
    const filter = queryOptions?.filter ?? getFilter(state);
    const query = { ...FILTER_QUERY_MAP[filter] };
    const order = getOrder(state);
    const search = queryOptions?.search ?? getSearch(state);
    const page = Router.query.page as string | undefined;
    const defaultPage = '1';

    query.page = parseInt(page ?? defaultPage);

    if (search) {
        query.search = search;
    }

    if (order.length > 0) {
        query.order = order;
    }

    if (workspaceIds) {
        query.workspaceId = workspaceIds;
    }

    if (crmCampaignsOnly) {
        query.status = CAMPAIGN_STATUS.live;
        query.trackingVersion = 'v4';
    }

    return qs.stringify(query);
};

export const getCampaignWithPagination = (
    response: AxiosResponse<ResponseData<CampaignResource[], null, Pagination>>,
): CampaignsWithPagination => {
    return {
        pagination: getMetaFromResponse(response),
        campaigns: getDataFromResponse(response),
    };
};

export const getHasHeaderColumn = (
    columns: CampaignTableColumnItem[],
    columnName: CampaignColumnName,
): boolean => {
    return !!columns.find((column) => column?.name === columnName);
};

export const getCampaignByCurrentFilter = (
    campaigns: CampaignResource[] = [],
    currentFilter: CampaignFilter,
): CampaignResource[] => {
    return campaigns.filter((campaign) => {
        return !(
            currentFilter === CampaignFilter.offline &&
            getCampaignStatus(campaign) === CAMPAIGN_STATUS.offline &&
            getCampaignIsArchived(campaign)
        );
    });
};

export const isCampaignDeletionReminderSet = () =>
    !!localStorage.getItem(CAMPAIGN_DELETION_REMINDER_LS_KEY);

export const shouldRepublishFunnelForEmbedPreview = (campaign: CampaignResource) => {
    if (!campaign || !('attributes' in campaign)) {
        return false;
    }

    const { launchedAt } = campaign.attributes;

    return !launchedAt || new Date(launchedAt) < new Date('2024-03-17');
};
