// All the FE tracking events

// IMPORTANT: Use "proper case" for tracking events, e.g. "Something Something Clicked"

export const TRACKING_EVENTS = {
    campaign: {
        overview: {
            pin: 'Funnel Pinning Used',
        },
        settings: {
            show: 'Funnel Settings Opened',
        },
        integrations: {
            settings: {
                show: 'Integration Tile Clicked',
            },
            zapier: {
                clicked: 'Zapier Integration Tile Clicked',
            },
        },
        integrationProfile: {
            video: {
                played: '[Integration Profile] Video Played',
            },
            helpCenter: {
                clicked: '[Integration Profile] Helpcenter Button Clicked',
            },
            faq: {
                clicked: '[Integration Profile] FAQ Clicked',
            },
            contactUs: {
                clicked: '[Integration Profile] Contact Us Clicked',
            },
        },
        editor: {
            undo: 'Undo Used',
            viewport: {
                switched: 'Funnel Viewport Switched',
            },
            pageLinking: {
                changed: 'Page Linking Changed',
            },
            resultPageLogic: {
                added: 'Result Page Logic Added',
            },
            personalization: {
                added: 'Personalization Added',
                clicked: 'Personalization Clicked',
            },
            sections: {
                used: 'Section Used',
            },
            aiText: {
                used: 'AI Text Used',
            },
            ai: {
                use: 'AI Used',
            },
            fontSize: {
                changed: 'Font Size Changed',
            },
            fontStyle: {
                changed: 'Font Style Changed',
            },
            color: {
                changed: 'Color Changed',
            },
            link: {
                added: 'Link Added',
            },
            newLineMenu: {
                used: 'New Line Menu Used',
            },
            bgImage: {
                toggle: 'BG Image Toggle Used',
                upload: 'BG Image Upload Used',
                select: 'BG Image Select Used',
                crop: 'BG Image Crop Used',
                focalPoint: 'BG Image Focal Point Used',
                opacity: 'BG Image Opacity Used',
            },
            blocks: {
                payment: {
                    clicked: 'Payment Block Clicked',
                },
                countdown: {
                    clicked: 'Countdown Block Clicked',
                },
            },
        },
        publish: {
            navbar: {
                openPublishPage: 'Open Publish Page Clicked',
                fastPublish: {
                    clicked: 'Fast Publish Clicked',
                    shortcutUsed: 'Fast Publish Shortcut Used',
                    successToastOpenFunnelClicked: 'Fast Publish Success Toast Open Funnel Clicked',
                },
            },
            publishPage: {
                publishClicked: 'Published Via Publish Page',
                urlCopiedToClipboard: 'Publish Page: Funnel URL Copied To Clipboard',
                openFunnelClicked: 'Publish Page: Open Funnel Clicked',
                editLinkClicked: 'Publish Page: Edit Link Clicked',
                saveLinkClicked: 'Publish Page: Save Link Clicked',
                editLinkCanceledViaOutsideClick:
                    'Publish Page: Edit Link Canceled Via Outside Click',
                editLinkCanceledViaEscape: 'Publish Page: Edit Link Canceled Via Escape',
                connectNewDomainClicked: 'Publish Page: Connect New Domain Clicked',
                domainFromDomainListSelected: 'Publish Page: Domain From Domain List Selected',
                qrCodeClicked: 'Publish Page: QR Code Clicked',
            },
        },
        embed: {
            card: {
                clicked: 'Funnel Embed Card Clicked',
            },
            copyCodeButton: {
                clicked: 'Funnel Embed Code Button Clicked',
            },
            howToEmbedButton: {
                clicked: 'Funnel Embed How To Embed Button Clicked',
            },
            modalCopyCodeButton: {
                clicked: 'Funnel Embed Modal Copy Code Button Clicked',
            },
        },
        theme: {
            font: {
                builtIn: {
                    selected: 'Built-In Font Selected',
                },
                custom: {
                    added: 'Custom Font Added',
                    deleted: 'Custom Font Deleted',
                    selected: 'Custom Font Selected',
                },
            },
        },
    },
    crm: {
        fileUploaded: '[CRM] File Uploaded',
        empty: {
            action: '[CRM] Empty State Action Used',
        },
        newContactButton: {
            clicked: '[CRM] “New Contact” Clicked',
        },
        profile: {
            show: '[CRM] Profile Opened',
            action: '[CRM] Profile Property Action Used',
            activityLog: {
                show: '[CRM] Activity Tab Clicked',
                scroll: '[CRM] Activity Tab Scrolled',
            },
            notes: {
                show: '[CRM] Profile Notes Tab Clicked',
            },
        },
        table: {
            action: '[CRM] Table Property Action Used',
            show: '[CRM] Table Viewed',
        },
        kanban: {
            show: '[CRM] Kanban Viewed',
            property: {
                visibility: '[CRM] Kanban Property Visibility Set',
                order: '[CRM] Kanban Property Re-Ordered',
            },
        },
        sharing: {
            clickedShare: '[CRM] Share Button Clicked',
            clickedInvite: '[CRM] Invite Button Clicked',
        },
    },
    workspaces: {
        allWorkspaces: {
            searchUsed: '[AW] All Workspaces Search Used',
        },
        setup: {
            started: '[WS] Setup Started',
            iWillPayIntroductionCardClicked: '[WS] Setup: I Will Pay for Workspace Clicked',
            myCustomerWillPayIntroductionCardClicked:
                '[WS] Setup: My Customer Will Pay for Workspace Clicked',
            nameChosen: '[WS] Setup: Name Chosen',
            iconChosen: '[WS] Setup: Icon Chosen',
            iconUploaded: '[WS] Setup: Icon Uploaded',
            domainChosen: '[WS] Setup: Domain Chosen',
            funnelsAdded: '[WS] Setup: Funnels Added',
            usersInvited: '[WS] Setup: Users Invited',
        },
        statusTemplates: {
            templateCreated: '[WS] Template Created',
            templateApplied: '[WS] Template Applied',
        },
        linkCopied: '[WS] Workspace Link Copied',
        invites: {
            submitClicked: '[WS] Invite Submit Clicked',
            newInviteRoleChangeClicked: '[WS] New Invite Role Change Clicked',
            existingMemberRoleChangeClicked: '[WS] Existing Member Role Change Clicked',
            existingMemberInMultiplaceWorkspacesRoleChangeClicked:
                '[WS] Existing Member In Multiple Workspaces Role Change Clicked',
            removeMemberClicked: '[WS] Remove Member Clicked',
            cancelInviteClicked: '[WS] Cancel Invite Clicked',
            advancedRoleManagementCtaClicked: '[WS] Advanced Role Management CTA Clicked',
        },
    },
    helpTooltip: {
        show: 'Help Tooltip Opened',
    },
    referral: {
        overview: {
            show: 'Referral Overview Viewed',
        },
        sharing: {
            link: {
                copy: 'Referral Link Copied',
            },
        },
    },
    cmdk: {
        commandUsed: 'Command Used',
    },
    navigation: {
        workspaceSelector: {
            opened: '[NAV] Workspace Selector Opened',
            closed: '[NAV] Workspace Selector Closed',
            dropdown: {
                manageMembersClicked: '[NAV] Workspace Selector Dropdown Manage Members Clicked',
                workspaceSettingsClicked:
                    '[NAV] Workspace Selector Dropdown Workspace Settings Clicked',
                workspaceSelected: '[NAV] Workspace Selector Dropdown Workspace Selected',
                addWorkspaceClicked: '[NAV] Workspace Selector Dropdown Add Workspace Clicked',
                allWorkspacesClicked: '[NAV] Workspace Selector Dropdown All Workspaces Clicked',
            },
        },
        topLevelItems: {
            itemClicked: '[NAV] Top Level Item Clicked',
        },
        actions: {
            backButtonClicked: '[NAV] Back Button Clicked',
            homeButtonClicked: '[NAV] Home Button Clicked',
            inviteClicked: '[NAV] Invite Button Clicked',
        },
    },
    surveys: {
        onboarding: {
            workspaceNameChosen: '[SUR] Onboarding Workspace Name Chosen',
            workspaceIconChosen: '[SUR] Onboarding Workspace Icon Chosen',
        },
    },
};
