import { FEATURE_IDS } from '@/app/billing/constants';
import { NAME } from '@/app/editor/editor/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import {
    ArrowRightOnRectangleIcon,
    BoltIcon,
    GlobeAltIcon,
    LinkIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useState, useEffect, useMemo } from 'react';
import { Field, getFormValues } from 'redux-form';

import { navigateToBillingAndPromoteFeature } from '@/app/billing/helpers/navigateToBillingAndPromoteFeature';
import { getFeatureAvailability } from '@/app/company/models/company';
import { getBlockById } from '@/app/editor/blocks/models/blocks';
import {
    createLinkingPayload,
    getLinkingAsString,
    getPageIconComponent,
    getTrackingLinkingType,
} from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Linking/helper';
import { createPageOptions } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ResultMapping/helper';
import {
    getPopulatedOtherPagesByCampaignId,
    getPopulatedResultPagesByCampaignId,
} from '@/app/editor/pages/models/pageMapping';
import { getPageById } from '@/app/editor/pages/models/pages';
import { useAppSelector } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import CategorizedDropdown from '@/ui/components/_BlockEditFields/CategorizedDropdown';

import TextInput from '../TextInput';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { CategorizedDropdownButtonIcon } from '@/ui/components/_BlockEditFields/CategorizedDropdown';
import type { DropdownCategory } from '@/ui/types';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    pageId: string;
    blockId: string;
    campaignId: string;
    submit?: () => void;
}

const Linking = ({ campaignId, pageId, blockId, input, submit, meta: { form } }: Props) => {
    const { t } = useTranslation(NAME);
    const block = useAppSelector((state) => getBlockById(state, blockId));

    const hasDynamicLinkingFeature = useAppSelector(
        getFeatureAvailability(FEATURE_IDS.dynamicLinking),
    );

    const selectPopulatedOtherPages = useMemo(
        () => getPopulatedOtherPagesByCampaignId(campaignId),
        [campaignId],
    );
    const selectPopulatedResultPages = useMemo(
        () => getPopulatedResultPagesByCampaignId(campaignId),
        [campaignId],
    );

    const otherPages = useAppSelector(selectPopulatedOtherPages);
    const resultPages = useAppSelector(selectPopulatedResultPages);

    const isResultPage = useAppSelector((state) => {
        const page = getPageById(state, campaignId, pageId);

        return page?.attributes?.isResult;
    });

    const url = useAppSelector((state) => {
        const formValues = getFormValues(form)(state) as BlockResource;

        return formValues?.attributes?.content?.linking?.url;
    });

    const [categories, setCategories] = useState<DropdownCategory[]>([]);

    const currentValue = getLinkingAsString(input.value);

    useEffect(() => {
        let categories: DropdownCategory[] = [
            {
                name: 'Linking',
                options: [
                    {
                        value: 'external',
                        key: t('external-url'),
                        icon: 'link',
                    },
                ],
            },
            {
                name: t('pages'),
                options: [],
            },
            {
                name: t('results'),
                options: [],
            },
        ];

        if (!isResultPage) {
            categories[0].options.unshift({
                value: 'next',
                key: t('next-page'),
                icon: 'next',
            });
        }

        categories[1].options = createPageOptions(otherPages, 'others');
        categories[2].options = createPageOptions(resultPages, 'results');

        setCategories(categories);
    }, [t, pageId, otherPages, resultPages, isResultPage]);

    const handleSelect = async (value: string) => {
        const { onChange } = input;

        // Tracking
        track(TRACKING_EVENTS.campaign.editor.pageLinking.changed, {
            component_type: block?.attributes?.componentType,
            linking_type: getTrackingLinkingType(value, otherPages, resultPages),
        });

        // Show upsell if feature is not enabled and not linking to either next or external page
        if (value !== 'next' && value !== 'external' && !hasDynamicLinkingFeature) {
            return await navigateToBillingAndPromoteFeature(FEATURE_IDS.dynamicLinking);
        }

        const linking = createLinkingPayload(value, url);

        onChange(linking);

        if (submit) {
            setTimeout(submit);
        }
    };

    const icon: CategorizedDropdownButtonIcon = useMemo(() => {
        switch (getTrackingLinkingType(currentValue, otherPages, resultPages)) {
            case 'next_page':
                return ArrowRightOnRectangleIcon;
            case 'external_url':
                return LinkIcon;
            case 'funnel_page':
                return getPageIconComponent(currentValue, otherPages, 'others');
            case 'result_page': {
                return getPageIconComponent(currentValue, resultPages, 'results');
            }
            default:
                return BoltIcon;
        }
    }, [currentValue, otherPages, resultPages]);

    return (
        <>
            <CategorizedDropdown
                categories={categories}
                value={currentValue}
                onChange={handleSelect}
                buttonIcon={icon}
            />
            {currentValue === 'external' && (
                <div className="mt-2">
                    <Field
                        name="attributes.content.linking.url"
                        placeholder={t('example-link')}
                        component={TextInput}
                        icon={GlobeAltIcon}
                        onBlurSubmit={submit}
                    />
                </div>
            )}
        </>
    );
};

export default Linking;
